<template>
  <Modal
    :modalTitle="modalTitle"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="createArticle"
    :showModal="showModal"
    :actionButtonText="$t('Form.Control.Create')"
  >
    <template v-slot:content>
      <BaseInputField
        v-model="newArticle.Title"
        :label="$t('Views.Articles.ModalCreateArticle.Text_ArticleTitle')"
        :error="titleErrors"
        @input="$v.newArticle.Title.$touch()"
        @blur="$v.newArticle.Title.$touch()"
      />
    </template>
  </Modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
// const touchMap = new WeakMap()
import BaseInputField from '@/components/UI/Form/BaseInputField.vue'
import Modal from '@/components/UI/Components/Modal.vue'
import articleProvider from '@/providers/article'

export default {
  name: 'ModalCreateArticle',

  components: {
    BaseInputField,
    Modal,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    articleType: {
      type: String,
      default: 'Post',
    },

    itemId: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newArticle: null,
      modalTitle: ''
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Title: {
        required,
        minLength: minLength(10),
      },
    }
    return {
      newArticle: output,
    }
  },

  computed: {
    titleErrors() {
      const errors = []
      if (!this.$v.newArticle.Title.$dirty) return errors
      !this.$v.newArticle.Title.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.newArticle.Title.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 10 }))
      return errors
    },
  },

  created() {
    let tags = []

    this.newArticle = {
      Type: this.type,
      ItemId: this.itemId,
      ArticleType: this.articleType,
      Title: '',
      Short: '',
      Text: '',
      Tags: tags,
    }

    this.modalTitle = this.articleType === 'News' ?
      this.$t('Views.Articles.ModalCreateArticle.Modal_News_Title')
      :
      this.$t('Views.Articles.ModalCreateArticle.Modal_Post_Title')
  },

  methods: {
    async createArticle() {
      if (this.isSaving) { return }
      this.isSaving = true
      // Validate form
      await this.$v.$touch()
      // Stop processing when FORM data is invalid
      if (this.$v.$invalid) { return }

      try {
        let response = await articleProvider.methods.createArticle(this.newArticle)
        this.isSavingSuccess = true

        let t = setTimeout(() => {
          this.onClickCancel()
          this.$emit('articleCreated', response.data)
          clearImmediate(t)
        }, 1500);
      }
      catch {
        this.isSavingError = true
        this.isSaving = true
      }
    },
  },
}
</script>
