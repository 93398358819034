<template>
  <Modal
    :modalTitle="
      $t('Components.Article.ArticleDetail.ModalDeleteArticle.Modal_Title')
    "
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :successMessage="
      $t(
        'Components.Article.ArticleDetail.ModalDeleteArticle.Message_succesDeleting'
      )
    "
    :errorMessage="
      $t(
        'Components.Article.ArticleDetail.ModalDeleteArticle.Message_ErrorDeleting'
      )
    "
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteArticle"
    :showModal="showModal"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="delete-content-container">
        <div class="mb-4 is-size-5 has-text-centered">
          {{
            $t(
              'Components.Article.ArticleDetail.ModalDeleteArticle.Message_AreYouSure',
              { name: article.Title }
            )
          }}
        </div>

        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            :label="$t('General.Text_YesSure')"
            @click.ctrl.left.exact="deleteLink"
            class="has-text-left"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/UI/Components/Modal.vue'
import BaseCheckbox from '@/components/UI/Form/BaseCheckbox.vue'
import EventBus from '@/eventbus/event-bus'
import articleProvider from '@/providers/article'

export default {
  name: 'ModalDeleteArticle',

  components: {
    Modal,
    BaseCheckbox,
  },

  props: {
    article: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  methods: {
    async deleteArticle() {
      if (this.isDeleting) {
        return
      }
      this.isDeleting = true

      try {
        await articleProvider.methods.deleteArticle(this.article.Key)
        EventBus.$emit('showToast', {
          type: 'info',
          message: this.$t(
            'Components.Article.ArticleDetail.ModalDeleteArticle.Message_succesDeleting'
          ),
        })

        let t = setTimeout(() => {
          this.onClickCancel()
          clearTimeout(t)
        }, 1500)

        this.$emit('articleDeleted', this.article)
      } catch {
        this.isDeletingError = true
        this.isDeleting = false
      }
    },
  },
}
</script>
